export const adminLteConf = {
    sidebarLeftMenu: [
        {
            label: 'Menu de Navegacion',
            separator: true
        },
        {
            label: ' Inicio', route: '/',
            iconClasses: 'fa fa-home ', 
            pullRights: [
                { 
                    classes: 'label pull-right bg-green' 
                }
            ]
        },
        { 
            label: ' Usuarios', 
            route: '/usuarios', 
            iconClasses: 'fa fa-user-o', 
            pullRights: [
                { 
                    classes: 'label pull-right bg-green' 
                }
            ] 
        },
        { 
            label: ' Usuarios App', 
            route: '/usuariosA', 
            iconClasses: 'fa fa-user-o', 
            pullRights: [
                { 
                    classes: 'label pull-right bg-green' 
                }
            ] 
        },
        { 
            label: ' Carpetas de investigación', 
            route: '/carpetas', 
            iconClasses: 'fa fa-folder-o', 
            pullRights: [
                { 
                    classes: 'label pull-right bg-green' 
                }
            ] 
        },
        { 
            label: ' Indicios', 
            route: '/indicios', 
            iconClasses: 'fa fa-address-book-o', 
            pullRights: [
                { 
                    classes: 'label pull-right bg-green' 
                }
            ] 
        },
        { 
            label: ' Indicios', 
            route: '/peritos', 
            iconClasses: 'fa fa-address-book-o', 
            pullRights: [
                { 
                    classes: 'label pull-right bg-green' 
                }
            ] 
        }
    ]
};